const mockedApiData = {
  columnHeaders: {
    statusCodes: [
      'assetId',
      '200',
      '404',
      '206',
      '304',
      '301',
      '416',
      '500',
      '400',
    ],
    transformations: [
      'CMCD',
      'size',
      'flip_rotate',
      'width',
      'multipleTransformations',
    ],
  },
  data: [
    {
      1234: {
        statusCodes: [
          {
            200: { count: 6267 },
          },
          {
            404: { count: 1136 },
          },
          {
            206: { count: 1598 },
          },
          {
            304: { count: 120 },
          },
          {
            301: { count: 1 },
          },
          {
            416: { count: 4 },
          },
          {
            500: { count: 25 },
          },
          {
            400: { count: 5 },
          },
        ],
        transformations: [
          {
            CMCD: { count: 2745 },
          },
          {
            size: { count: 2356 },
          },
          {
            flip_rotate: { count: 235 },
          },
          {
            width: { count: 5076 },
          },
          {
            multipleTransformations: { count: 23 },
          },
        ],
      },
    },
    {
      2345: {
        statusCodes: [
          {
            200: { count: 7253 },
          },
          {
            404: { count: 1375 },
          },
          {
            206: { count: 1233 },
          },
          {
            304: { count: 106 },
          },
          {
            301: { count: 2 },
          },
          {
            416: { count: 3 },
          },
          {
            500: { count: 65 },
          },
          {
            400: { count: 15 },
          },
        ],
        transformations: [
          {
            CMCD: { count: 3643 },
          },
          {
            size: { count: 2346 },
          },
          {
            flip_rotate: { count: 456 },
          },
          {
            width: { count: 842 },
          },
          {
            multipleTransformations: { count: 53 },
          },
        ],
      },
    },
  ],
}

export default mockedApiData
