import * as React from 'react'
// import { wfetch } from '@wf-mfe/api'
import {
  Cell,
  Column,
  Row,
  TableView,
  TableBody,
  TableHeader,
  defaultTheme,
  Provider,
} from '@adobe/react-spectrum'

import mockedApiData from '../mocks/mock-api-data.js'

// eslint-disable-next-line import/prefer-default-export
export function AssetMetricsTable() {
  // @ts-ignore
  const defineSubColumns = (title) => {
    // @ts-ignore
    const headers = mockedApiData.columnHeaders[title]
    // @ts-ignore
    return headers.map((header) => <Column key={header}>{header}</Column>)
  }

  const defineColumns = () => {
    return Object.keys(mockedApiData.columnHeaders).map((title) => (
      <Column isRowHeader title={title} key={title}>
        {defineSubColumns(title)}
      </Column>
    ))
  }

  // const defineCell = () => {
  //   return mockedApiData.data(obj => {
  //     <Cell>{obj}</Cell>
  //   })
  // }

  return (
    <Provider theme={defaultTheme}>
      <TableView aria-label="Aem metrics">
        <TableHeader>{defineColumns()}</TableHeader>
        <TableBody>
          <Row>
            {/* {defineCell()} */}
            <Cell>2349238</Cell>
            <Cell>File folder</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
            <Cell>6/7/2020</Cell>
          </Row>
        </TableBody>
      </TableView>
    </Provider>
  )
}
